import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogsComponent } from './blogs/blogs.component';
import { NewsComponent } from './news/news.component';
import {MandirateComponent} from './mandirate/mandirate.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AuthguardService } from './services/authguard.service';


const routes: Routes = [
  { path: '', loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule) },
  { path: 'blogs', loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'blogdetails/:blog_title/:blog_id', loadChildren: () => import('./blogdetails/blogdetails.module').then(m => m.BlogdetailsModule) },
  { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: 'newsdetail/:news_title/:news_id', loadChildren: () => import('./newsdetail/newsdetail.module').then(m => m.NewsdetailModule) },
  { path: 'event', loadChildren: () => import('./event/event.module').then(m => m.EventModule) },
  { path: 'eventdetails/:event_id', loadChildren: () => import('./eventdetails/eventdetails.module').then(m => m.EventdetailsModule) },
  { path: 'directorylisting/:catname/:catid/:type', loadChildren: () => import('./directorylisting/directorylisting.module').then(m => m.DirectorylistingModule) },
  // { path: 'listingdetails/:listingid/:org_name/:parent_cat_id/:sub_cat_id', loadChildren: () => import('./listingdetails/listingdetails.module').then(m => m.ListingdetailsModule) },
  { path: 'listingdetails/:org_name/:listingid', loadChildren: () => import('./listingdetails/listingdetails.module').then(m => m.ListingdetailsModule) },
  { path: 'listingcriteria', loadChildren: () => import('./listingcriteria/listingcriteria.module').then(m => m.ListingcriteriaModule) },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'aboutus', loadChildren: () => import('./aboutus/aboutus.module').then(m => m.AboutusModule) },
  { path: 'agrobusiness', loadChildren: () => import('./agrobusiness/agrobusiness.module').then(m => m.AgrobusinessModule) },
  { path: 'membership', loadChildren: () => import('./membership/membership.module').then(m => m.MembershipModule) },
  { path: 'authenticategateway', loadChildren: () => import('./authenticategateway/authenticategateway.module').then(m => m.AuthenticategatewayModule) },
  { path: 'createlisting', loadChildren: () => import('./createlisting/createlisting.module').then(m => m.CreatelistingModule), canActivate: [AuthguardService] },
  { path: 'createstore', loadChildren: () => import('./createstore/createstore.module').then(m => m.CreatestoreModule), canActivate: [AuthguardService] },
  { path: 'agrolist/:agro_cat_id/:agrocatname', loadChildren: () => import('./agrolist/agrolist.module').then(m => m.AgrolistModule) },
  { path: 'agro', loadChildren: () => import('./agro/agro.module').then(m => m.AgroModule) },
  { path: 'myagrolistings', loadChildren: () => import('./myagrolistings/myagrolistings.module').then(m => m.MyagrolistingsModule), canActivate: [AuthguardService] },
  { path: 'editmyagrolist/:agro_id', loadChildren: () => import('./editmyagrolist/editmyagrolist.module').then(m => m.EditmyagrolistModule), canActivate: [AuthguardService] },
  { path: 'mandirate', loadChildren: () => import('./mandirate/mandirate.module').then(m => m.MandirateModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
