import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpRequest, HttpEvent, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
    constructor(
        private _router: Router,
        private _storageService: StorageService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: string = '';
        if (this._storageService.get('idToken') !== null) {
            token = this._storageService.get('idToken');
        }

        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
        if (!request.headers.has('Content-type')) {
            if (!request.url.includes('createCategory')) {
                request = request.clone({ headers: request.headers.set('Content-type', 'application/json') });
            }
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  //console.log(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                //console.log(error);
                return throwError(error);
            })
        )
    }
}
