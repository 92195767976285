import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  constructor(
    private _http: HttpClient,
    private _storageService: StorageService
  ) { }

  registerUser = (data) => {
    return this._http.post(environment.API_URL + 'registerUser', data);
  }

  logUser = (data) => {
    return this._http.post(environment.API_URL + 'logUser', data);
  }

  isUserAuthenticated = () => {
    if (this._storageService.get('idToken') === null) {
      return false;
    }
    else if (this._storageService.get('idToken') === '') {
      return false;
    }
    else if (this._storageService.get('idToken') === undefined) {
      return false;
    }
    else {
      return true;
    }
  }
}
