import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(
    private _http: HttpClient
  ) { }

  getBlogs = (limit: number, offset: number, params) => {
    return this._http.post(environment.API_URL + 'getAllBlogs/' + limit + '/' + offset, params);
  }

  getVideos = (postObj) => {
    return this._http.post(environment.API_URL + 'getVideos', postObj);
  }

  getBlog = (postData) => {
    return this._http.post(environment.API_URL + 'getBlog', postData);
  }

  getBlogInterested = (postData) => {
    return this._http.post(environment.API_URL + 'getBlogInterested', postData);
  }

  getBusiness = (limit: number, offset: number) => {
    return this._http.post(environment.API_URL + 'getBusiness/' + limit + '/' + offset, null);
  }

  getBusinessByLetter = (limit: number, offset: number, letter: string) => {
    return this._http.post(environment.API_URL + 'getBusinessByLetter/' + limit + '/' + offset + '/' + letter, null);
  }
}
