import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GenericService } from 'src/app/services/generic.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {
  @Input() deleteType: any;
  @Input() catEditData: any;


  @Output() close = new EventEmitter();
  deleteEvent = new EventEmitter();

  displayModal: boolean = true;
  isLoading: boolean = false;
  constructor(
    private _genericService: GenericService,
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  closeModal = () => {
    this.close.emit();
  }

  remove = () => {
    if (this.deleteType == 'agrolist') {
      this.deleteAgroList();
    }
  }

  deleteAgroList = () => {
    this.isLoading = true;
    this._genericService.deleteAgroList({ agro_id: this.catEditData.id }).subscribe(res => {
      if (res['status'] != 0) {
        this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not delete the Listing', life: 5000 });
      }
      else {
        this.closeModal();
        this.deleteEvent.emit();
      }
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      console.log(err);
      this._messageService.add({ severity: 'error', summary: 'Something went wrong', detail: 'Try Later', life: 5000 });
    })
  }

}
