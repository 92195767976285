<!--Navbar-->
<mdb-navbar id="mainNavbar" SideClass="navbar navbar-expand-lg navbar-light fixed-top whiteBg pt-0 pb-0 mb-5" [containerInside]="false">

    <!-- Navbar brand -->
    <mdb-navbar-brand class="brand-width">
        <a class="navbar-brand h3-semi-bold" href="#">
            <img src="assets/images/Annadata-4.jpg" class="logo" />
        </a>
    </mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>

        <!-- Links -->
        <ul class="navbar-nav ml-5 mr-auto v-align" id="main-nav" >
            <div class="h-align">
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/aboutus">About</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/blogs">Blog</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/news">Agri News</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/listingcriteria">Directory</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/agrobusiness">Agro Mart</a>
                </li>

                
                <!--
                <li class="nav-item mr-2">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/mandirate">Mandi Rate</a>
                </li>
                -->
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/agro">Agro Store</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                        [routerLinkActiveOptions]="{exact: true}" routerLink="/event">Events</a>
                </li>
                <li class="nav-item ">
                    <a class="nav-link waves-light" mdbWavesEffect routerLinkActive="class-active"
                    [routerLinkActiveOptions]="{exact: true}" routerLink="/membership">Membership</a>
                </li>
                <li class="nav-item " style="padding-left: 6px;">
                  <a class="get-app-btn-header" href="https://play.google.com/store/apps/details?id=com.annadataonline.annadataapp">Get the App</a>
                </li>
            </div>

            <div class="h-align">
                <li class="nav-item ">
                    <div #translate id="google_translate_element"></div>
                </li>
    
                <li class="nav-item">
                    <span *ngIf="!showHeaderValues">
                        <!-- <button class="btn btn-sm btn-cyangrey waves-effect btn-pad-adj" (click)="authenticate()" data-toggle="collapse" data-target="#navbarCollapse">Login /
                            Register
                        </button> -->
                        <button class="btn btn-sm btn-cyangrey waves-effect btn-pad-adj" (click)="authenticate()">Login /
                            Register
                        </button>
                    </span>
                    <span *ngIf="showHeaderValues">
                        <li class="nav-item dropdown" style="list-style-type: none;" dropdown>
                            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" style="text-transform: capitalize;
                            font-weight: 500;
                            color: black !important;" mdbWavesEffect>
                                Welcome, {{headerValue}}<span class="caret"></span></a>
                            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                                <a class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/createlisting']">Create
                                    Listing</a>
                                <a *ngIf="showAgroStore" class="dropdown-item waves-light" mdbWavesEffect [routerLink]="['/createstore']">Create
                                    Agro Store</a>
                                <a class="dropdown-item waves-light" mdbWavesEffect (click)="logOut()">Log Out</a>
                            </div>
                        </li>
                    </span>
                </li>
            </div>


        </ul>
        <!-- Links -->

        <!-- Search form -->
        <!-- <ul class="google_translate_element-list">
          <li class="nav-item mr-2">
            <div #translate id="google_translate_element"></div>
          </li>
        </ul> -->

        
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->
