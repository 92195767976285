<div class="col-sm-12 mb-5" style="margin-top: 80px;">
    <div class="card">
        <div class="card-body">
            <b>Terms &amp; Conditions:</b>
            <p>Though all efforts have been made to ensure the accuracy and currency of the content on this web
                directory( www.annadataonline.com ), the same should not be construed as a statement of law or used
                for any legal purposes.</p>
            <p>
                www.annadataonline.com accepts no responsibility in relation to the accuracy, completeness,
                usefulness or otherwise, of the contents. Users are advised to verify/check any information with the
                relevant Government department(s) and/or other source(s), and to obtain any appropriate professional
                advice before acting on the information provided in the directory.
            </p>
            <p>
                Links to other websites that have been included on this web directory are provided for public
                convenience only. www.annadataonline.com is not responsible for the contents or reliability of linked
                websites and does not necessarily endorse the view expressed within them. When you select a link to an
                outside website, you are leaving the Web Directory and are subject to the privacy and security policies
                of the owners/sponsors of the outside website. www.annadataonline.com does not guarantee the
                availability of such linked pages at all times. www.annadataonline.com cannot authorize the use of
                copyrighted materials contained in linked websites. Users are advised to request such authorization
                from the owner of the linked website. www.annadataonline.com does not guarantee that linked
                websites comply with Indian Government Website Guidelines.
            </p>
            <p>
                The information posted on this website could include hypertext links to information created and
                maintained by other organizations. The links given to external sites do not constitute an
                endorsement of information, products or services offered by these sites. When you select a link to an
                outside website, you are leaving the site and are subject to the privacy and security policies of the
                owners/sponsors of the outside website.
            </p>
            <p>
                In no event will the www.annadataonline.com be liable for any expense, loss or damage including,
                without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever
                arising from use, or loss of use, of data, arising out of or in connection with the use of this web
                directory.
            </p>
            <p>
                These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any
                dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the
                courts of India.
            </p>
            <p>
                Despite our best efforts, we do not guarantee that the documents in this site are free from infection by
                computer viruses etc.
            </p>
            <strong class="sm-text">For further details please mail: annadata-india@annadataonline.com</strong>
        </div>
    </div>
</div>