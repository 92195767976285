import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmitterService {
  private bannerEmit = new BehaviorSubject<any>(undefined);
  bannerEmitValue = this.bannerEmit.asObservable();

  private headerEmit = new BehaviorSubject<any>(undefined);
  headerEmitValue = this.headerEmit.asObservable();

  constructor() { }

  getBannerValue = (data) => {
    this.bannerEmit.next(data);
  }

  getLogValueForHeader = (data) => {
    this.headerEmit.next(data);
  }
}
