<div *ngIf="deleteType=='agrolist'">
    <p-dialog header="Delete Agro List" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="p-m-0">
            Are you sure to delete the List titled <strong>{{catEditData.title}}</strong> ? <br />This action is
            irreversible
            <div class="button-blocks mt-3 d-flex justify-content-flex-end">
                <div *ngIf="isLoading; else butBlock" class="d-flex justify-content-center">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}"></p-progressSpinner>
                </div>
                <ng-template #butBlock>
                    <button type="submit" class="btn btn-danger m-0 mr-2" (click)="remove()"
                        style="color: white;">Proceed</button>
                    <button type="button" class="btn btn-dark m-0" (click)="closeModal()"
                        style="color: white;">Cancel</button>
                </ng-template>
            </div>
        </div>
    </p-dialog>
</div>