<div class="col-sm-12 mb-5" style="margin-top: 80px;">
    <div class="card">
        <div class="card-body">
            <b>Privacy Policy</b>
            <p>
                As a general rule, this website does not collect Personal Information about you when you visit the site.
                You can generally visit the site without revealing Personal Information, unless you choose to provide
                such information.
            </p>

            <p>
                <b>Site Visit Data:</b> This website records your visit and logs the following information for
                statistical purposes
                your server&#39;s address; the name of the top-level domain from which you access the Internet (for
                example, .gov, .com, .in, etc.); the type of browser you use; the date and time you access the site; the
                pages you have accessed and the documents downloaded and the previous Internet address from which
                you linked directly to the site.
            </p>
            <p>
                <b>Cookies:</b> A cookie is a piece of software code that an internet web site sends to your browser
                when you
                access information at that site. This site does not use cookies.
            </p>
            <p>
                <b>Email Management:</b> Your email address will only be recorded if you choose to send a message. It
                will
                only be used for the purpose for which you have provided it and will not be added to a mailing list.
                Your
                email address will not be used for any other purpose, and will not be disclosed, without your consent.
            </p>
            <p>
                <b>Collection of Personal Information:</b> If you are asked for any other Personal Information you will
                be
                informed how it will be used if you choose to give it. If at any time you believe the principles
                referred to
                in this privacy statement have not been followed, or have any other comments on these principles,
                please notify Annadataonline through the contact us page.
            </p>
            <p>
                <i>
                    Note: The use of the term &quot;Personal Information&quot; in this privacy statement refers to any
                    information
                    from which your identity is apparent or can be reasonably ascertained.
                </i>
            </p>
            <p>
                For further details please mail: ceo@annadataonline.com ; annadata-india@annadataonline.com
            </p>
        </div>
    </div>
</div>
