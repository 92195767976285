import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(
    private _http: HttpClient
  ) { }

  getCategoryList = () => {
    return this._http.post(environment.API_URL + 'getListCategories', null);
  }

  getAgroCategoryList = () => {
    return this._http.post(environment.API_URL + 'getAgroListCategories', null);
  }

  getAgroCategoryListById = (data) => {
    return this._http.post(environment.API_URL + 'getAgroListCategoriesById', data);
  }

  getBlogs = (limit, offset) => {
    return this._http.post(environment.API_URL + 'getBlogMainApp/' + limit + '/' + offset, null);
  }

  getNews = (limit, offset) => {
    return this._http.post(environment.API_URL + 'getNewsMainApp/' + limit + '/' + offset, null);
  }

  getCategoryListFormatted = () => {
    return this._http.post(environment.API_URL + 'getCategoryListFormatted', null);
  }

  getBanners = (page) => {
    return this._http.get(environment.API_URL + 'getBanners/' + page);
  }

  getOtherBanners = (page) => {
    return this._http.get(environment.API_URL + 'getBanners/' + page);
  }

  getAds = (page) => {
    return this._http.get(environment.API_URL + 'getAds/' + page);
  }

  getImage = () => {
    return this._http.post(environment.API_URL + 'getImage', null);
  }

  getLongAdsData = () => {
    return this._http.get(environment.API_URL + 'getLongAdsData');
  }

  createCategory = (formData) => {
    return this._http.post(environment.API_URL + 'createCategory', formData);
  }

  getSubCategoriesForParent = (dataObj) => {
    return this._http.post(environment.API_URL + 'getSubCategoriesForParent', dataObj);
  }
  getSubSubCategoriesForParent = (dataObj) => {
    return this._http.post(environment.API_URL + 'getSubSubCategoriesForParent', dataObj);
  }

  getAttributeList = () => {
    return this._http.post(environment.API_URL + 'getAttributeList', null);
  }

  getCountries = () => {
    return this._http.post(environment.API_URL + 'getCountries', null);
  }

  postListing = (data) => {
    return this._http.post(environment.API_URL + 'directoryListingUser', data);
  }

  agroListPost = (data) => {
    return this._http.post(environment.API_URL + 'agroListPost', data);
  }

  agroListUpdate = (data) => {
    return this._http.post(environment.API_URL + 'agroListUpdate', data);
  }

  agroList = (agrocatid, value) => {
    return this._http.get(environment.API_URL + 'agroList/' + agrocatid + '/' + value);
  }

  getMyAgroList = () => {
    return this._http.post(environment.API_URL + 'myAgroList', null);
  }

  getMyAgroListById = (data) => {
    return this._http.post(environment.API_URL + 'getMyAgroListById', data);
  }

  deleteAgroList = (data) => {
    return this._http.post(environment.API_URL + 'deleteAgroList', data);
  }
}
