import { Component, NgModule, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GenericService } from 'src/app/services/generic.service';
import { BlogService } from 'src/app/services/blog.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isBlogLoading: boolean = false;
  queryParamsValue: any;
  blogs: any;
 
  blogList: any = [];
  constructor(public _genericService: GenericService,
    public _messageService: MessageService,
    private _blogService: BlogService) { 
      this.queryParamsValue = { str: '', cat_id: '' };
  }

  ngOnInit(): void {
    this.getBlogs()
    //this.getNews();
  }


  getBlogs = () => {
   
    this.isBlogLoading = true;
    this._blogService.getBlogs(0,6, this.queryParamsValue).subscribe(res => {
      if (res['status'] != 0) {
        this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Could not load blogs', life: 5000 });
      }
      else {
        this.blogList = res['resultObj']['blogs'];
       
      }
     
      this.isBlogLoading = false;
    }, err => {
      console.log(err);
      this._messageService.add({ severity: 'error', summary: 'Something went wrong', detail: 'Try Later', life: 5000 });
      this.isBlogLoading = false;
    })
  }

}