<div class="section section-footer">
    <div class="container">
        <div class="row" style="justify-content: space-between;">
            <div class="col-sm-4 p-3">
                <div class="d-flex flex-column grey-fnt">
                    <div class="site-logo">
                        <img src="assets/images/Annadata-2.jpg" style="max-width: 55%;" />
                    </div>
                    <div class="site-basic">
                        Indian Agriculture, Food Processing, Horticulture, Fishery, Animal Husbandry and LiveStock
                        Business Networking Directory
                    </div>
                    <div class="site-loc mt-3">
                        <div class="d-flex mb-2">
                            <span class="site-ico mr-2">
                                <mdb-icon fas icon="map-marker-alt"></mdb-icon>
                            </span>
                            <span class="site-desc">
                                Food Park, Bengaluru – 560022, Karnataka
                            </span>
                        </div>
                        <div class="d-flex mb-2">
                            <span class="site-ico mr-2">
                                <mdb-icon fab icon="whatsapp"></mdb-icon>
                            </span>
                            <span class="site-desc">
                                +91-09903740726 / +91-07797218158
                            </span>
                        </div>
                        <div class="d-flex mb-2">
                            <span class="site-ico mr-2">
                                <mdb-icon far icon="envelope"></mdb-icon>
                            </span>
                            <span class="site-desc">
                                query@annadataonline.com
                            </span>
                        </div>
                        <div class="d-flex mb-2">
                            <span class="site-ico mr-2">
                                <mdb-icon far icon="comment-alt"></mdb-icon>
                            </span>
                            <span class="site-desc">
                                complaint@annadataonline.com
                            </span>
                        </div>
                    </div>
                    <div class="social-icons d-flex mt-2">
                        <a class="sco-icons ico-fb mr-2" href="https://www.facebook.com/Exporters.aggrigator"
                            target="_blank">
                            <mdb-icon fab icon="facebook-f"></mdb-icon>
                        </a>
                        <a class="sco-icons ico-twt mr-2" href="https://twitter.com/annadataonline" target="_blank">
                            <mdb-icon fab icon="twitter"></mdb-icon>
                        </a>
                        <a class="sco-icons ico-tube" href="https://www.youtube.com/channel/UC5QnmksuB-6_ubTU3VfWDvw"
                            target="_blank">
                            <mdb-icon fab icon="youtube"></mdb-icon>
                        </a>
                    </div>
                </div>
            </div>
                <!-- <div class="col-sm-4 p-3">
                    <div class="footer-headers grey-fnt pb-2" style="border-bottom: 1px solid #656565;">
                        Recent Blogs Posts
                    </div>
                    <ul class="p-0">
                                <section id="intro-wrap" class="intro-five-Wrap text-left text-white">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                                    <div class="carousel-inner">
                                                        <div class="carousel-item active">
                                                            <div class="card p-grid p-nogutter mb-2">
                                                                <div class="card-body p-0">
                                                                    <div [ngStyle]="{'background': 'url('+ blogList[0]?.blog_image+') no-repeat center center', 'background-size': 'cover', 'height': '120px'}"
                                                                        *ngIf="blogList[0]?.blog_image != ''"></div>
                                                                    <div [ngStyle]="{'background': 'url(assets/images/noimage.png) no-repeat center center', 'background-size': 'cover', 'height': '120px'}"
                                                                        *ngIf="blogList[0]?.blog_image == ''"></div>
                                                                    <div class="news-description m-3">
                                                                        <div class="blog-title pl-3 pr-3">
                                                                            {{blogList[0].blog_title}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="carousel-item" *ngFor="let blogs of blogList" >
                                                            <div class="container">
                                                                <div class="card p-grid p-nogutter mb-2">
                                                                    <div class="card-body p-0">
                                                                        <div [ngStyle]="{'background': 'url('+blogs.blog_image+') no-repeat center center', 'background-size': 'cover', 'height': '120px'}"
                                                                            *ngIf="blogs.blog_image != ''"></div>
                                                                        <div [ngStyle]="{'background': 'url(assets/images/noimage.png) no-repeat center center', 'background-size': 'cover', 'height': '120px'}"
                                                                            *ngIf="blogs.blog_image == ''"></div>
                                                                        <div class="news-description m-3">
                                                                            <div class="blog-title pl-3 pr-3">
                                                                                {{blogs.blog_title}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                
                                    </div>
                                    <div class="overlay"></div>
                                </section>
                            
                        
                    </ul>
                </div> -->
            <div class="col-sm-4 p-3">
                <div class="footer-headers grey-fnt pb-2" style="border-bottom: 1px solid #656565;">
                    Some useful links
                </div>
                <ul class="p-0">
                    <li class="d-flex mt-3 grey-fnt">
                        Contact Us
                    </li>
                    <li class="d-flex mt-3 grey-fnt">
                        About Us
                    </li>
                    <li class="d-flex mt-3 grey-fnt">
                        Explore Directories
                    </li>
                    <li class="d-flex mt-3 grey-fnt">
                        <a [routerLink]="['/terms']">Terms &amp; Conditions</a>
                    </li>
                    <li class="d-flex mt-3 grey-fnt">
                        <a [routerLink]="['/privacy']">Privacy Policy</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="footer-inner d-flex justify-content-center p-2 grey-fnt">
        &copy; 2015, annadataonline.com
    </div>
</div>