import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MDBBootstrapModule, ModalModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './_layouts/footer/footer.component';
import { HeadersComponent } from './_layouts/headers/headers.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { AuthguardService } from './services/authguard.service';
import { HttpInterceptor } from './interceptors/httpinterceptor';
import { DeleteComponent } from './components/delete/delete.component';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MandirateComponent } from './mandirate/mandirate.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeadersComponent,
    TermsComponent,
    PrivacyComponent,
    DeleteComponent,
    MandirateComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    DialogModule,
    ProgressSpinnerModule,

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true
    },
    MessageService,
    AuthguardService
  ],
  entryComponents: [DeleteComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
