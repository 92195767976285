import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EmitterService } from 'src/app/services/emitter.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  showHeaderValues: boolean = false;
  showAgroStore: boolean = false;
  headerValue: string;
  @ViewChild("translate") elementRef: ElementRef;

  constructor(
    private _router: Router,
    private _emitterService: EmitterService,
    private _storageService: StorageService
  ) { }

  ngOnInit(): void {
    this._emitterService.headerEmitValue.subscribe(res => {
      if (res !== undefined) {
        console.log(res);
        this.showHeaderValues = true;
        this.headerValue = res;
        if(this.showHeaderValues==true)
        {
          this.showAgroStore=(this._storageService.get('reg_type') == null || this._storageService.get('reg_type') == '')
          || this._storageService.get('reg_type')=='free' ? false : true;
        }
        //console.log(this.showHeaderValues +' '+ this.showAgroStore);
      }
      else {
        this.showHeaderValues = (this._storageService.get('name') == null || this._storageService.get('name') == '') ? false : true;
        this.headerValue = this._storageService.get('name');
        if(this.showHeaderValues==true)
        {
          this.showAgroStore=(this._storageService.get('reg_type') == null || this._storageService.get('reg_type') == '')
          || this._storageService.get('reg_type')=='free' ? false : true;
        }
        //console.log(this.showHeaderValues +' '+ this.showAgroStore);
      }
    })
    // angular.element(document).ready(function () {
    //   // Your document is ready, place your code here
    // });
  }

  ngAfterViewInit() {
    var script1 = document.createElement("script");
    script1.type = "text/javascript";
    //debugger;
    script1.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'en', autoDisplay: false, includedLanguages : 'en,bn,gu,hi,kn,mr,or,pa,ta,te', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element'); } ";
    this.elementRef.nativeElement.appendChild(script1);
    var script2 = document.createElement("script");
    script2.type = "text/javascript";
    script2.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
    this.elementRef.nativeElement.appendChild(script2);

    // let style1 = document.getElementsByClassName('goog-te-banner-frame skiptranslate') as HTMLCollectionOf<HTMLElement>;
    // debugger;
    // if(style1[0]!== undefined)
    // {
    //   let style2= document.getElementsByClassName('goog-te-banner-frame skiptranslate') as HTMLCollectionOf<HTMLElement>;
    //   style2[0].style.display  = 'none'
    //   document.body.style.top = '0px';
    // }
  }

  authenticate = () => {

    this._router.navigate(['/authenticategateway']);
  }

  logOut = () => {
    this._storageService.remove('idToken');
    this._storageService.remove('id');
    this._storageService.remove('name');
    this.showHeaderValues = false;
    this._router.navigate(['/']);
  }

}
